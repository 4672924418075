<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
    >
      <div class="d-flex align-items-center mb-4 mt-4">
        <div class="h3">
          Управление очередями
        </div>
      </div>

      <b-row class="mb-4">
        <b-col class="w-100">
          <b-input-group class="bg-white border border-primary rounded">
            <div class="d-flex w-100">
              <v-select
                v-model="queueId"
                :reduce="q => q.id"
                :options="queueList"
                placeholder="Выберите очередь"
                label="name"
                :clearable="true"
                class="w-100 queue-select"
                @input="consultationsFetch"
              />
              <b-button
                variant="outline-primary"
                :type="$const.PRIMARY_BUTTON"
                class="rounded-0 border-0"
                @click="consultationsFetch"
              >
                <b-icon icon="search" />
              </b-button>
            </div>
          </b-input-group>
        </b-col>
      </b-row>
      <div
        v-if="!consultationList.length && !isLoading"
        class="px-3"
      >
        Ничего не найдено
      </div>

      <template v-if="isLoading">
        <preloader style="margin-top: 20px" />
      </template>

      <b-table
        v-if="consultationList.length && !isLoading"
        class="bg-white table"
        bordered
        :fields="fields"
        :items="consultationList"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #cell(createdAt)="row">
          <template v-if="row.item.createdAt">
            {{ formatDate(new Date(row.item.createdAt), 'dd.MM.yyyy') }}
          </template>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(clientInfo)="row">
          <template v-if="row.item.clientInfo">
            {{ row.item.clientInfo.fullName }}
          </template>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(status)="row">
          <template v-if="row.item.status != null">
            {{ consultationStatuses[row.item.status] }}
          </template>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(action)="row">
          <b-button
            size="sm"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="text-nowrap"
            @click="openConsultationControlModal(row.item)"
          >
            Отменить талон
          </b-button>
        </template>
      </b-table>

      <div
        v-if="consultationList.length / perPage > 1"
        class="d-flex align-items-center mb-4"
      >
        <b-form-select
          v-model="perPage"
          size="sm"
          :options="[10, 25, 50]"
          style="width: 70px"
          class="mr-2"
        />
        <b-pagination
          v-model="currentPage"
          :total-rows="consultationList.length"
          :per-page="perPage"
          size="sm"
          class="m-0"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  format,
} from '@evd3v/date-fns';

import { mixinRoles } from '@/mixins';
import Preloader from '@/components/Preloader';

import { QUEUE_CONSULTATION_STATUSES } from '@/helpers/consts';

export default {
  name: 'QueuesControl',
  page: {
    title: 'CRM Doctis - Управление очередью',
  },
  components: {
    Preloader,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isLoading: false,
      queueId: null,
      queueList: [],
      consultationList: [],
      perPage: 25,
      currentPage: 1,
      consultationStatuses: QUEUE_CONSULTATION_STATUSES,
      fields: [
        {
          key: 'createdAt',
          label: 'Дата записи',
        },
        {
          key: 'clientInfo',
          label: 'ФИО пациента',
        },
        {
          key: 'status',
          label: 'Статус',
        },
        {
          key: 'action',
          label: '',
          class: 'td-button',
        },
      ],
    };
  },
  async created() {
    await this.queuesFetch();
  },
  methods: {
    formatDate(value, variant) {
      return format(value, variant);
    },
    async queuesFetch() {
      this.isLoading = true;
      try {
        this.queueList = await this.$store.dispatch(this.$types.QUEUES_FETCH);
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    async consultationsFetch() {
      this.consultationList = [];

      this.isLoading = true;
      try {
        this.consultationList = await this.$store.dispatch(this.$types.QUEUE_CONSULTATIONS_FETCH, this.queueId);
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    openConsultationControlModal({ id }) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'QueueConsultationDeleteModal',
        props: {
          consultationId: id,
          afterDelete: () => {
            this.consultationsFetch();
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep.table .td-button {
    width: 50px;
  }

  ::v-deep.queue-select{
    .vs__selected {
      line-height: 2.2;
    }
    .vs__dropdown-toggle {
      border: none;
    }
  }
</style>
